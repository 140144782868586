function Step(this: any, context, t) {
  this._context = context
  this._t = t
}

Step.prototype = {
  areaStart: function () {
    this._line = 0
  },
  areaEnd: function () {
    this._line = NaN
  },
  lineStart: function () {
    this._x = this._y = NaN
    this._point = 0
  },
  lineEnd: function () {
    if (0 < this._t && this._t < 1 && this._point === 2)
      this._context.lineTo(this._x, this._y)
    if (this._line || (this._line !== 0 && this._point === 1))
      this._context.closePath()
    if (this._line >= 0) (this._t = 1 - this._t), (this._line = 1 - this._line)
  },
  point: function (x, y) {
    x = +x
    y = +y
    switch (this._point) {
      case 0:
        this._point = 1
        this._line ? this._context.lineTo(x, y) : this._context.moveTo(x, y)
        break
      case 1:
      default: {
        if (this._t <= 0) {
          this._context.lineTo(this._x, y)
          this._context.lineTo(x, y)
        } else {
          const x1 = this._x * (1 - this._t) + x * this._t
          if (this._point === 1) {
            // Move past the first line
            this._context.moveTo(x1, this._y)
          } else {
            this._context.lineTo(x1, this._y)
          }
          this._context.lineTo(x1, y)
        }
        if (this._point === 1) this._point = 2
        break
      }
    }
    this._x = x
    this._y = y
  },
}

export function step(context) {
  return new Step(context, 1)
}
