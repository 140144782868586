import { isEmpty, compact, uniq, uniqBy } from 'lodash'

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
}

export const rjust = (
  value: number | string,
  length: number,
  prefix: string
): string => {
  if (!value) {
    return ''
  }

  if (value.toString().length >= length) {
    return value.toString()
  }

  let result = value.toString()
  while (result.length < length) {
    result = `${prefix}${result}`
  }

  return result
}

type Group = { grp: string }
export const groupSort = (a: Group, b: Group) => {
  if (a.grp > b.grp) return 1
  if (a.grp < b.grp) return -1
  return 0
}

type Activity = { activityDatetime: string }
export const activitySort = (a: Activity, b: Activity) => {
  const dateA = new Date(a.activityDatetime)
  const dateB = new Date(b.activityDatetime)
  if (dateA > dateB) return -1
  if (dateA < dateB) return 1
  return 0
}

type Country = { countryCode: string; countryName: string }
export const countrySort = (a: Country, b: Country) => {
  if (a.countryName > b.countryName) return 1
  if (a.countryName < b.countryName) return -1
  return 0
}

export const isBlank = (value: any): boolean => {
  if (value === undefined || value === null || value === '') {
    return true
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return isEmpty(compact(value))
    } else {
      return isEmpty(value)
    }
  } else {
    return false
  }
}

export const toArray = (value: any) => {
  if (isBlank(value) || !Array.isArray(value)) {
    return []
  } else {
    return value
  }
}

export const map = (container: any, callback: any) => {
  if (!container) {
    return null
  } else if (typeof container === 'object' && Array.isArray(container)) {
    return container.map(callback)
  } else if (typeof container === 'object' && !Array.isArray(container)) {
    return Object.keys(container).map((key, index) =>
      callback(key, container[key], index)
    )
  } else if (typeof container === 'number') {
    return Array.from(Array(container)).map(callback)
  } else {
    return null
  }
}

export const capitalize = (value: string): string => {
  if (value) {
    return value[0].toUpperCase() + value.substring(1)
  } else {
    return ''
  }
}

export const initials = (value: string): string => {
  if (value) {
    return value
      .split(' ')
      .filter((word) => word)
      .map((word) => word[0].toUpperCase())
      .join('')
  } else {
    return ''
  }
}

export const plural = (count: number, word: string): string => {
  if (count === 1) {
    return word
  } else {
    if (word.endsWith('y')) {
      return word.replace(/y$/, 'ies')
    } else if (word.endsWith('f')) {
      return word.replace(/f$/, 'ves')
    } else {
      return word + 's'
    }
  }
}

export const truncate = (
  str: string,
  length: number,
  omission = '...'
): string => {
  if (str.length <= length) {
    return str
  } else {
    return str.slice(0, length - omission.length) + omission
  }
}

export const host = (url: string): string => {
  if (typeof url === 'string') {
    return url.replace(/https?:\/\//, '').replace(/\/.*/, '')
  } else {
    return ''
  }
}

export const hostpath = (url: string): string => {
  if (typeof url === 'string') {
    return url.replace(/https?:\/\//, '').replace(/[?&#].*/, '')
  } else {
    return ''
  }
}

export const toUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url
  } else {
    return 'https://' + url
  }
}

export const newTableSort =
  (callback?: any) =>
  <T>(collection: T[], key: string, dir: 'asc' | 'desc'): T[] => {
    if (!collection || !Array.isArray(collection)) {
      return []
    } else if (collection && key) {
      return collection.sort((a: T, b: T) => {
        let valA = a[key]
        let valB = b[key]

        if (callback) {
          const [newValA, newValB] = callback(a, b, key) || []
          if (newValA !== undefined) valA = newValA
          if (newValB !== undefined) valB = newValB
        }

        if (dir === 'desc') {
          const temp = valA
          valA = valB
          valB = temp
        }

        if (valA > valB) return 1
        if (valA < valB) return -1
        return 0
      })
    } else {
      return collection || []
    }
  }

export const newTableSortExt =
  (callback?: any) =>
  <T>(collection: T[], sorts: { sort: string; dir: 'asc' | 'desc' }[]): T[] => {
    if (!collection || !Array.isArray(collection)) {
      return []
    } else if (collection && sorts.length) {
      let result = collection
      sorts = uniqBy(sorts, (sort) => sort.sort)
      for (const sort of sorts) {
        const key = sort.sort
        const dir = sort.dir
        if (key) {
          result = result.sort((a: T, b: T) => {
            let valA = a[key]
            let valB = b[key]

            if (callback) {
              const [newValA, newValB] = callback(a, b, key) || []
              if (newValA !== undefined) valA = newValA
              if (newValB !== undefined) valB = newValB
            }

            if (dir === 'desc') {
              const temp = valA
              valA = valB
              valB = temp
            }

            if (valA > valB) return 1
            if (valA < valB) return -1
            return 0
          })
        }
      }
      return result
    } else {
      return collection || []
    }
  }

export const filterBy = <T>(
  collection: T[],
  callback: (item: T) => boolean
): T[] => {
  if (isBlank(collection) || !Array.isArray(collection)) {
    return []
  }

  return collection.filter(callback)
}

export const filterBySearch = <T>(
  collection: T[],
  search: string,
  valueGetter: (item: T) => string[]
): T[] => {
  if (isBlank(collection) || !Array.isArray(collection)) {
    return []
  }

  if (!search) {
    return [...collection]
  }

  const searchVariants = uniq(
    [
      search.toLowerCase(),
      search
        .toLowerCase()
        .replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/[/?#].*$/, ''),
    ].map((search) => search.trim())
  ).filter((search) => search)

  if (!searchVariants?.length) {
    return [...collection]
  }

  return collection.filter((item) => {
    const values = valueGetter(item)
    for (const value of values) {
      if (
        searchVariants.some((search) => value.toLowerCase().includes(search))
      ) {
        return true
      }
    }
    return false
  })
}

export const toQuery = (params: any): string =>
  Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val as any)}`)
    .join('&')

export const parseQuery = (search: string) =>
  search
    ? search
        .replace(/^\?/, '')
        .split('&')
        .reduce((result: { [key: string]: string }, pair: string) => {
          const [key, value] = pair.split('=')
          if (key) result[decodeURIComponent(key)] = decodeURIComponent(value)
          return result
        }, {})
    : {}

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

export const svgArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`
}

export const isValidDate = (str: string): boolean => {
  return !isInvalidDate(str)
}

export const isInvalidDate = (str: string): boolean => {
  return (
    !str ||
    str === 'Invalid date' ||
    str === '1000-01-01 00:00:00' ||
    str === '0000-00-00 00:00:00'
  )
}

export const filterTableColumns = (
  columnsString: string,
  availableColumns: string[],
  defaultColumns?: string[]
): string[] => {
  if (!columnsString) {
    return defaultColumns || availableColumns
  }

  const columns = columnsString.split(',')
  const result: string[] = []

  for (const column of columns) {
    if (availableColumns.includes(column)) {
      result.push(column)
    }
  }

  if (isBlank(result)) {
    return defaultColumns || availableColumns
  } else {
    return result
  }
}

export const responseError = (
  response,
  defaultError = 'Something went wrong'
): string => {
  if (response?.data && typeof response?.data === 'string') {
    return response?.data
  } else if (
    response?.data &&
    typeof response?.data?.[0]?.[1]?.details?.[0]?.message === 'string'
  ) {
    return response?.data?.[0]?.[1]?.details?.[0]?.message
  } else if (response?.message && typeof response?.message === 'string') {
    return response?.message
  } else {
    return defaultError
  }
}

export const sleep = (timeout: number) =>
  new Promise((resolve: any) => setTimeout(resolve, timeout))

export const splitUrl = (url: string): [string, string] => {
  if (!url) return ['', '']
  const normalizedUrl = url.replace(/https?:\/\//, '')
  const splitIndex = normalizedUrl.indexOf('/')
  if (splitIndex === -1) {
    return [normalizedUrl, '/']
  } else {
    return [normalizedUrl.slice(0, splitIndex), normalizedUrl.slice(splitIndex)]
  }
}
